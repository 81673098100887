import config from '../../config/config.json'
import userActions from "@/store/user/user-actions";
import store from "@/store";

type Message = {
    type?: string
    event?: string
    data?: {
        height?: number
    }
    payload?: any
}


export function siteConfig () {

    const isLogged = !!getCookie('sb');

    function getCookie(cname: string) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }

    const sportsBookUrl = config.sportsBookUrl ?? ''
    const useParam = config.useParam ?? false
    const miniGames = config.miniGames ?? []
    const registerEnabled = config.registerEnabled ?? false
    const loginEnabled = config.loginEnabled ?? false
    const apiUrl = config.apiUrl ?? ''
    const googleAnalyticsTag = config.googleAnalyticsTag ?? ''
    const languagesList = config.languagesList ?? []
    const currencies = config.currencies ?? []
    const sportsbookDynamicHeight = config.sportsbookDynamicHeight ?? false
    const oddsFormat = config.oddsFormat ?? []

    return {
        isLogged,
        sportsBookUrl,
        apiUrl,
        useParam,
        miniGames,
        registerEnabled,
        loginEnabled,
        googleAnalyticsTag,
        languagesList,
        currencies,
        sportsbookDynamicHeight,
        oddsFormat
    }
}

export function getDomain() {
    const secondLevelDomains = ['.co.uk', '.co.net', '.co.nz', '.co.at', '.co.nl'];
    let domain = '.';

    const parts = window.location.hostname.split('.');
    const count = parts.length;

    if (count > 1) {
        const newParts = [parts[count - 2], parts[count - 1]];
        domain += newParts.join('.');

        if (count > 2 && secondLevelDomains.indexOf(domain) != -1) {
            domain = '.' + parts[count - 3] + domain;
        }
    }
    else {
        domain += window.location.hostname;
        console.log('Bad Domain Format: ' + domain);
    }

    return domain;
}

export const handlePostMessage = function (e: Message) {
    const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : e.data

    switch (messageData.type) {
        case 'SET_ROUTE_AND_VIEW':
            store.commit('SET_PATH', messageData.payload.path)
            store.commit('SET_VIEW', messageData.payload.view)
            break
        case 'USER_LOGGED_IN':
            if (!messageData.payload) {
                store.dispatch(userActions.LOGOUT_USER)
            }
            break
        case 'SET_GAMIFICATION_POPUP_OPENED':
            if (messageData?.payload) {
                document.body.classList.add("missions-opened");
            }else {
                document.body.classList.remove("missions-opened");
            }
            break
        case 'NAVIGATE_TO_SCREEN':
            if (messageData?.payload?.name === 'LogIn' && !siteConfig()?.isLogged) {
                store.commit('OPEN_LOGIN_MODAL', true)
            }
            break
        default:
            break
    }

    switch (messageData.event) {
        case 'update_height':
            updateIframeHeight(messageData.data.height)
            break
        default:
            break
    }
}

export const sendPostMessage = function (actionType: string, payload?: any) {
    const iframe = document.getElementById("sportsbookcontainer") as HTMLIFrameElement | null;
    if (!iframe) {
        console.error("Iframe not found");
        return;
    }
    let message;
    switch (actionType) {
        case 'NAVIGATE_TO':
            if (!payload) {
                console.error("There is no given path to navigate");
                return;
            }
            message = JSON.stringify({
                type: 'NAVIGATE_TO',
                payload: payload
            });
            break;
        case 'OPEN_MY_SPORTSBOOK_MODAL':
            message = JSON.stringify({
                type: 'OPEN_MY_SPORTSBOOK_MODAL',
            });
            break;
        case 'SET_USER_ODDS_FORMAT':
            message = JSON.stringify({
                type: 'SET_USER_ODDS_FORMAT',
                payload: payload
            });
            break;
        default:
            console.error("Invalid action type");
            return;
    }
    iframe.contentWindow?.postMessage(message, '*');
};

const updateIframeHeight = (value: number): void => {
    if (!config.sportsbookDynamicHeight) {
        return
    }

    document.getElementById('sportsbookcontainer')?.setAttribute('height', value.toFixed())
}
